<template>
  <div id="content" class="project-page">
    <v-wait for="load project" transition="fade" mode="out-in">
      <template slot="waiting">
        <div class="row">
          <div class="col-sm-12 vh-100 d-flex justify-content-center my-4">
            <b-spinner variant="primary" label="Spinning" />
          </div>
        </div>
      </template>
      <div class="container">
        <div class="row my-4">
          <div class="col-12 col-lg-8 mb-4">
            <ProjectOverview
              v-if="!projectDetailSettings?.enableFoerderApp"
              id="project-overview"
              ref="projectOverviewRef"
              :project="project"
              :projectDetailSettings="projectDetailSettings"
            />
            <ProjectOverviewFoerderapp
              v-if="projectDetailSettings?.enableFoerderApp"
              id="project-overview-hds"
              ref="projectOverviewRef"
              :project="project"
              :projectDetailSettings="projectDetailSettings"
              @tab-changed="handleTabChanged"
            />
          </div>
          <div class="col-12 col-lg-4">
            <div class="row">
              <div id="project-detail-contact-person" class="col-12 order-first order-lg-first">
                <ContactPerson
                  v-if="currentContactPerson && projectDetailSettings.enableFoerderApp"
                  :contactPerson="currentContactPerson"
                />
              </div>

              <div id="project-detail-social-media" class="col-12 order-first">
                <SocialMediaArea
                  v-if="!projectDetailSettings?.enableFoerderApp"
                  :project="project"
                  :projectDetailSettings="projectDetailSettings"
                />
                <SocialMediaAreaFoerderApp
                  v-if="projectDetailSettings?.enableFoerderApp"
                  :project="project"
                  :projectDetailSettings="projectDetailSettings"
                />
              </div>

              <div class="col-12">
                <DonationInfo
                  v-if="showDonationInfo"
                  id="donation-info"
                  :project="project"
                  :projectDetailSettings="projectDetailSettings"
                  :isOrganization="isOrganization"
                />
              </div>

              <div class="col-12">
                <VotingInfo
                  v-if="showVotingInfo"
                  id="voting-info"
                  :project="project"
                  :currentRank="project.current_voting_rank"
                  :totalRank="project.participating_projects"
                  :receivedVotes="project.current_votes + newVotes"
                  :isOrganization="isOrganization"
                />
              </div>

              <div v-if="coFundings.length > 0" class="col-12 order-first order-lg-last">
                <CoFundingInfo id="cofunding-info" :coFundings="coFundings" />
              </div>

              <div class="col-12 order-first order-lg-last">
                <VotingArea
                  v-if="showVotingArea"
                  id="voting-area"
                  :project="project"
                  :projectDetailSettings="projectDetailSettings"
                  :isOrganization="isOrganization"
                  @vote="addVotes"
                />
              </div>

              <div id="project-detail-support-alerts" class="col-12 order-first order-lg-last">
                <SfAlert :show="isFullyFunded && showDonationInfo" class="mt-3">
                  <translate key="fully-funded-info">This project is fully funded already.</translate>
                </SfAlert>
                <SfAlert :show="isExpired && !isFullyFunded && showDonationInfo" class="mt-3">
                  <translate key="expired-info">This project has ended already.</translate>
                </SfAlert>
              </div>

              <div class="col-12 order-first order-lg-last">
                <SupportProjectArea
                  v-if="showSupportArea && !isPreview && !project?.is_status_archived"
                  id="support-project-area"
                  :project="project"
                  :projectDetailSettings="projectDetailSettings"
                  :userBalance="userBalance"
                  :isOrganization="isOrganization"
                  @donated="onDonation"
                  @anon-redeemed="updateNavbar"
                  @non-anon-redeemed="updateUserBalance"
                />
              </div>

              <div class="col-12 order-first order-lg-last">
                <PrivateDonationArea
                  v-if="showPrivateDonationArea"
                  id="private-donation-area"
                  :project="project"
                  :projectDetailSettings="projectDetailSettings"
                  :coFundings="coFundings"
                  :isOrganization="isOrganization"
                  @overfunded="refreshProject"
                />
              </div>

              <div class="col-12 order-first order-lg-last">
                <InfoArea
                  v-if="!projectDetailSettings?.enableFoerderApp && project && 'address' in project"
                  id="info-area"
                  :project="project"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DonationSuccessModal
        v-model="donationSuccessModalOpen"
        :donationResult="donationResult"
        :userProfile="userProfile"
        :project="project"
        :projectDetailSettings="projectDetailSettings"
        @close="onSuccessModalClose"
      />
      <VoteSuccessModal
        v-model="voteSuccessModalOpen"
        :voteResponse="voteResponse"
        :userProfile="userProfile"
        :project="project"
        :projectDetailSettings="projectDetailSettings"
        @close="closeVoteSuccessModalOpen"
      />
    </v-wait>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import SfAlert from '@/components/SfAlert.vue'
import UserMixin from '@/mixins/UserMixin'
import { ExploreModule } from '@/store/modules/explore'
import { ICurrencyDisplaySettings, IProjectDetailSettings } from '@/types/cms'
import { ICoFunding } from '@/types/finances'
import { IContactPerson } from '@/types/foerderApp'
import { IExploreOrganizationDetail } from '@/types/organizations'
import { IDonationResult, IExploreProjectDetail } from '@/types/projects'
import { IVoteResponse } from '@/types/votings'
import { API_URLS } from '@/utils/helpers'

import DonationSuccessModal from '../modals/DonationSuccessModal.vue'
import VoteSuccessModal from '../modals/VoteSuccessModal.vue'
import CoFundingInfo from './CoFundingInfo.vue'
import ContactPerson from './ContactPerson.vue'
import DonationInfo from './DonationInfo.vue'
import ProjectOverviewFoerderapp from './hds/ProjectOverviewFoerderapp.vue'
import SocialMediaAreaFoerderApp from './hds/SocialMediaAreaFoerderApp.vue'
import InfoArea from './InfoArea.vue'
import PrivateDonationArea from './PrivateDonationArea.vue'
import ProjectOverview from './ProjectOverview.vue'
import SocialMediaArea from './SocialMediaArea.vue'
import SupportProjectArea from './SupportProjectArea.vue'
import VotingArea from './VotingArea.vue'
import VotingInfo from './VotingInfo.vue'

@Component({
  name: 'project-detail',
  components: {
    ProjectOverview,
    ProjectOverviewFoerderapp,
    DonationInfo,
    VotingArea,
    SupportProjectArea,
    VotingInfo,
    SfAlert,
    PrivateDonationArea,
    ContactPerson,
    SocialMediaArea,
    SocialMediaAreaFoerderApp,
    CoFundingInfo,
    InfoArea,
    DonationSuccessModal,
    VoteSuccessModal,
  },
})
export default class ExploreDetail extends Mixins(UserMixin) {
  @Prop({ default: false }) isOrganization!: boolean
  project: IExploreProjectDetail | IExploreOrganizationDetail = null
  newVotes = 0
  activeTabKey = ''
  donationResult: IDonationResult = null
  donationSuccessModalOpen = false
  voteResponse: IVoteResponse = null
  voteSuccessModalOpen = false

  scrollIntoView() {
    const element = (this.$refs.projectOverviewRef as Vue)?.$el

    if (element) {
      const elementY = element.getBoundingClientRect().top

      // if the detail container is not within 300px from the top, then scroll to it
      if (elementY > 300) {
        // 20px offset from the top so it doesn't look terrible
        const yOffset = 20
        const y = element.getBoundingClientRect().top + window.scrollY - yOffset

        window.scrollTo({ top: y, behavior: 'smooth' })
      }

      return
    }

    // Component hasn't rendered yet
    setTimeout(() => {
      this.scrollIntoView()
    }, 200)
  }

  mounted() {
    this.$nextTick(() => this.scrollIntoView())
  }

  get currentContactPerson(): IContactPerson {
    if (!this.project || !('contact_person' in this.project)) {
      return null
    }
    let contactPerson = null
    if (this.activeTabKey === 'key_organization') {
      contactPerson = this.project.organization?.contact_person
    } else if (this.activeTabKey === 'key_project') {
      contactPerson = this.project.contact_person
    } else {
      contactPerson = this.project.funding_scope?.contact_person
    }
    return contactPerson || null
  }

  get projectDetailSettings(): IProjectDetailSettings {
    return ExploreModule.projectDetailSettings
  }

  get coFundings(): ICoFunding[] {
    return ExploreModule.coFundings
  }

  get isPreview(): boolean {
    return !!this.$route.query?.preview_token
  }

  get isFullyFunded(): boolean {
    if (this.project && 'fully_funded' in this.project) {
      return this.project.fully_funded
    }
    return false
  }

  get isExpired(): boolean {
    if (this.project && 'expired' in this.project) {
      return this.project.expired
    }
    return false
  }

  get showDonationInfo(): boolean {
    if (this.$route.name === 'project-detail-voting') {
      return false
    }

    if (this.project?.is_status_archived) {
      return this.projectDetailSettings?.displayArchivedCoinBalance !== 3
    }
    return this.projectDetailSettings?.displayCurrentCoinBalance !== 3
  }

  get showSupportArea(): boolean {
    if (this.projectDetailSettings?.enableFoerderApp) {
      return false
    }

    if (!this.project) {
      return false
    }

    if ('expired' in this.project) {
      if (this.project.is_status_archived || this.project.expired || this.project.is_finished) {
        return false
      }
    } else if (this.project?.is_status_archived) {
      return false
    }

    return true
  }

  get showVotingArea(): boolean {
    if (this.projectDetailSettings?.enableFoerderApp) {
      return false
    }
    if (!this.project) {
      return false
    }
    if (!this.project?.voting) {
      return false
    }
    if (
      !this.projectDetailSettings?.enableVoting ||
      this.isPreview ||
      this.project?.is_status_archived ||
      ('expired' in this.project && this.project.expired)
    ) {
      return false
    }
    return true
  }

  get currencyDisplay(): ICurrencyDisplaySettings {
    return this.projectDetailSettings?.currencyDisplay
  }

  get showVotingInfo(): boolean {
    if (!this.project?.voting || !this.projectDetailSettings?.displayCurrentVotingRank) {
      return false
    }
    return this.$moment(this.project.voting.voting_start) < this.$moment() || !!this.$route.params.votingSlug
  }

  get detailURL(): string {
    let previewToken = this.$route.query?.preview_token
    if (Array.isArray(previewToken)) {
      previewToken = previewToken[0]
    }
    let url = ''
    if (this.isOrganization) {
      url = API_URLS.EXPLORE.ORGANIZATIONS.RETRIEVE(this.$route.params.orgSlug)
      if (this.$route.params.votingSlug) {
        url = API_URLS.EXPLORE.ORGANIZATIONS.RETRIEVE_VOTING(this.$route.params.orgSlug, this.$route.params.votingSlug)
      }
      if (previewToken) {
        url = API_URLS.EXPLORE.ORGANIZATIONS.PREVIEW(this.$route.params.orgSlug, previewToken)
      }
    } else {
      url = API_URLS.EXPLORE.PROJECTS.RETRIEVE(this.$route.params.projectSlug)
      if (this.$route.params.votingSlug) {
        url = API_URLS.EXPLORE.PROJECTS.RETRIEVE_VOTING(this.$route.params.projectSlug, this.$route.params.votingSlug)
      }
      if (previewToken) {
        url = API_URLS.EXPLORE.PROJECTS.PREVIEW(this.$route.params.projectSlug, previewToken)
      }
    }
    return url
  }

  get showPrivateDonationArea(): boolean {
    if (this.project && 'expired' in this.project) {
      return !this.project.fully_funded && !this.project.expired
    }
    return this.projectDetailSettings?.enablePrivateDonations && !this.isPreview && !this.project?.is_status_archived
  }

  handleTabChanged(tabKey: string): void {
    this.activeTabKey = tabKey
  }

  formatCurrency(value: number): string {
    const displayName = this.currencyDisplay.useCurrency
      ? this.currencyDisplay.currencySymbol
      : this.currencyDisplay.coinNamePlural
    return `${value} ${displayName}`
  }

  updateNavBalance(): void {
    for (const balance of document.querySelectorAll<HTMLElement>('.coin-balance')) {
      balance.innerText = this.currencyDisplay.useCurrency
        ? this.userProfile.balance.in_currency_display
        : this.formatCurrency(this.userProfile.balance.in_coins)
    }
  }

  updateUserBalance(): void {
    this.fetchUserBalance().then(() => {
      this.updateNavBalance()
    })
  }

  addVotes(voteResponse: IVoteResponse): void {
    this.voteResponse = voteResponse
    if (!voteResponse.verification_needed) {
      this.newVotes += voteResponse.new_votes
      this.voteSuccessModalOpen = true
    }
  }

  async updateNavbar(): Promise<void> {
    await axios.get(API_URLS.V3.TOPBAR_DATA).then((response) => {
      document.querySelector<HTMLElement>('.adjust-navbar').innerHTML = response.data.template
    })
    this.updateNavBalance()
  }

  onDonation(result: IDonationResult): void {
    this.donationResult = result
    this.loadProject()
    this.updateUserBalance()
    this.donationSuccessModalOpen = !this.donationSuccessModalOpen
  }

  onSuccessModalClose(): void {
    this.donationResult = null
    this.donationSuccessModalOpen = !this.donationSuccessModalOpen
  }

  closeVoteSuccessModalOpen(): void {
    this.voteResponse = null
    this.voteSuccessModalOpen = false
  }

  async refreshProject(): Promise<void> {
    await axios.get(this.detailURL).then((response) => {
      this.project = response.data
    })
  }

  async loadProject(): Promise<void> {
    this.$wait.start('load project')

    if (ExploreModule.projectDetailSettings) {
      await axios.get(this.detailURL).then((response) => {
        this.project = response.data
      })
    } else {
      await Promise.all([
        ExploreModule.fetchProjectDetailSettings(),
        ExploreModule.fetchCofundings(),
        axios.get(this.detailURL).then((response) => {
          this.project = response.data
        }),
      ])
    }

    this.$wait.end('load project')
  }

  async created(): Promise<void> {
    this.fetchUserProfile().then(() => this.updateUserBalance())
    await this.loadProject()

    document.title = `${this.project.title} - ${this.projectDetailSettings?.siteName}`
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
